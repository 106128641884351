exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogo-servizi-cloud-js": () => import("./../../../src/pages/catalogo-servizi-cloud.js" /* webpackChunkName: "component---src-pages-catalogo-servizi-cloud-js" */),
  "component---src-pages-crediti-js": () => import("./../../../src/pages/crediti.js" /* webpackChunkName: "component---src-pages-crediti-js" */),
  "component---src-pages-glossario-js": () => import("./../../../src/pages/glossario.js" /* webpackChunkName: "component---src-pages-glossario-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notizie-js": () => import("./../../../src/pages/notizie.js" /* webpackChunkName: "component---src-pages-notizie-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-programma-abilitazione-cloud-js": () => import("./../../../src/pages/programma-abilitazione-cloud.js" /* webpackChunkName: "component---src-pages-programma-abilitazione-cloud-js" */),
  "component---src-pages-qualificazione-servizi-cloud-js": () => import("./../../../src/pages/qualificazione-servizi-cloud.js" /* webpackChunkName: "component---src-pages-qualificazione-servizi-cloud-js" */),
  "component---src-pages-strategia-cloud-pa-js": () => import("./../../../src/pages/strategia-cloud-pa.js" /* webpackChunkName: "component---src-pages-strategia-cloud-pa-js" */),
  "component---src-templates-in-deep-abilitazione-template-js": () => import("./../../../src/templates/InDeepAbilitazioneTemplate.js" /* webpackChunkName: "component---src-templates-in-deep-abilitazione-template-js" */),
  "component---src-templates-in-deep-qualification-template-js": () => import("./../../../src/templates/InDeepQualificationTemplate.js" /* webpackChunkName: "component---src-templates-in-deep-qualification-template-js" */),
  "component---src-templates-in-deep-strategy-template-js": () => import("./../../../src/templates/InDeepStrategyTemplate.js" /* webpackChunkName: "component---src-templates-in-deep-strategy-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/NewsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

